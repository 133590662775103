import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from './Markdown';

interface MainProps {
    title: string;
}

export default function Main(props: MainProps) {
    const {title} = props;

    return (
        <Grid item xs={12} md={8} sx={{'& .markdown': {py: 3,},}}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <Divider/>
            <Markdown/>
        </Grid>
    );
}
